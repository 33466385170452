<template>
 <!--Content start-->
    <main id="content">
      <!-- End video background -->
      <div class="container">
        <!--CONTENT START-->
        <div class="row">
          <!-- start left column -->
          <div class="col-md-8">
            <!--Block start-->
            <div class="block-area">
              <!--block title-->
              <!-- <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <span class="bg-primary text-white arabic-kufi">انثوغرافيك</span>
                </h4>
              </div> -->
              <div class="row">
                <!--post start-->
                <article class="col-sm-6" v-for="publish in video.slice(0,4)" :key="publish.id">
                  <div class="card card-full hover-a mb-module">
                    <!--thumbnail & category-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                        <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline>
                          <source :src="publish.trailer" type="video/mp4">
                        </video> -->
                        <!-- play button -->
                        <div class="post-type-icon">
                          <span class="fa-stack-sea text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                            </svg>
                          </span>
                        </div>
                      </a>
                      <a class="position-absolute m-2 b-0 p-1 badge bg-primary text-white" href="#"></a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h3 class="card-title h2 h3-sm h4-md h3-lg pointer arabic-kufi">
                        <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                      </h3>
                      <!-- author, date & comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold arabic-kufi" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-21 arabic-kufi">{{ publish.upload_date.substring(0,10) }}</time>
                        <span title="5 comment" class="float-end">
                          <span class="icon-comments"></span> 
                        </span>
                      </div>
                      <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> -->
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <!--End Block-->
          </div>
          <!-- end left column -->

          <!-- start right column -->
          <aside class="col-md-4 end-sidebar-lg">
            <!--Sidebar sticky-->
            <div class="sticky">
              <!--Block start-->
              <aside class="widget">
                <!--block title-->
                <!-- <div class="block-title-4">
                  <h4 class="h5 title-arrow arabic-kufi">
                    <span>مزيد من انثوغرافيك</span>
                  </h4>
                </div> -->
                <!-- block-content -->
                <div class="row"> 
                  <!--post start-->
                  <article class="col-6 col-md-12 col-lg-6" v-for="publish in video.slice(4,10)" :key="publish.id">
                    <div class="card card-full hover-a mb-4">
                      <!--thumbnail-->
                      <div class="ratio_230-129 image-wrapper">
                        <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                        <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline> 
                          <source :src="publish.trailer" type="video/mp4">
                        </video> -->
                          <!-- play button -->
                          <div class="post-type-icon hover-show">
                            <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                          </div>
                        </a>
                      </div>
                      <div class="card-body">
                        <!-- date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <time datetime="2019-10-21 arabic-kufi">{{ publish.upload_date.substring(0,10) }}</time>
                          <span title="5 comment" class="float-end">
                            <span class="icon-comments"></span> 
                          </span>
                        </div>
                        <!--title-->
                        <h2 class="card-title h5 h4-sm h5-md h6-lg arabic-kufi pointer"><a @click="getFeed(publish)">{{ publish.title_ar.substring(0,50)+".." }}</a></h2>
                      </div>
                    </div>
                  </article>
                  <!--post start-->
                </div>
                <!-- end block content -->
              </aside>
              <!--End Block-->
            </div>
          </aside>
          <!-- end right column -->

          <!-- start left column -->
          <div class="col-md-8">
            <!-- block start -->
            <div class="block-area">
              <!-- block title -->
              <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <span class="bg-primary text-white arabic-kufi">اخبار فيديو</span>
                </h4>
              </div>
              <div class="gap-0"></div>
              <!-- block content -->
              <div class="border-bottom-last-0 first-pt-0">
                <!--post start-->
                <article class="card card-full hover-a py-4" v-for="publish in video.slice(10,16)" :key="publish.id">
                  <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6">
                      <!--thumbnail-->
                      <div class="ratio_360-202 image-wrapper">
                        <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                        <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline>
                          <source :src="publish.trailer" type="video/mp4">
                        </video> -->
                          <!-- play button -->
                          <div class="post-type-icon hover-hide">
                            <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6">
                      <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
                        <!--title-->
                        <h3 class="card-title h2 h3-sm h2-md arabic-kufi pointer">
                          <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                        </h3>
                        <!--author and date-->
                        <div class="card-text mb-2 text-muted small">
                          <span class="d-none d-sm-inline me-1">
                            <a class="fw-bold arabic-kufi" href="#">تاق برس</a>
                          </span>
                          <time datetime="2019-10-21">{{ publish.upload_date.substring(0,10) }}</time>
                        </div>
                        <!--description-->
                        <p class="card-text arabic-kufi pointer">{{ publish.desc_ar.substring(0,100)+".." }}</p>
                      </div>
                    </div>
                  </div>
                </article>
                <!--post start-->
              </div>
              <!-- end block content -->
            </div>
            <!--end block-->
          </div>
          <!-- end left column -->
          <!-- start right column -->
          <aside class="col-md-4 end-sidebar-lg">
            <!--Sidebar sticky-->
            <div class="sticky">
              <!-- latest post -->
              <aside class="widget">
                <!--Block title-->
                <div class="block-title-4">
                  <h4 class="h5 title-arrow arabic-kufi">
                    <span>مزيد من اخبار الفيديو</span>
                  </h4>
                </div>
                <!--post big start-->
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(16,17)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                        <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline>
                          <source :src="publish.trailer" type="video/mp4">
                        </video> -->
                        <!-- play button -->
                        <div class="post-type-icon">
                          <span class="fa-stack-sea text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h2 class="card-title h1-sm h3-md arabic-kufi pointer">
                        <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                      </h2>
                      <!-- author & date -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold arabic-kufi" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22 arabic-kufi">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                      <p class="card-text arabic-kufi pointer">{{ publish.desc_ar.substring(0,100)+".." }}</p>
                    </div>
                  </article>
                </div>
                <!--end post big-->
                <!--post small start-->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(17,20)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                          <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                            <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline>
                              <source :src="publish.trailer" type="video/mp4">
                            </video> -->
                            <!-- play button -->
                            <div class="post-type-icon hover-show">
                              <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md arabic-kufi pointer">
                            <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                          </h3>
                          <div class="card-text small text-muted arabic-kufi pointer">
                            <time datetime="2019-10-16">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--End post list-->
                </div>
                <!--end post small-->
                <div class="gap-0"></div>
              </aside>
              <!-- end latest post -->
              <aside class="widget">
                <!--Block title-->
                <div class="block-title-4">
                  <h4 class="h5 title-arrow arabic-kufi">
                    <!-- <span>مزيد من اخبار الفيديو</span> -->
                  </h4>
                </div>
                <!--post big start-->
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(20,21)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                        <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline>
                          <source :src="publish.trailer" type="video/mp4">
                        </video> -->
                        <!-- play button -->
                        <div class="post-type-icon">
                          <span class="fa-stack-sea text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h2 class="card-title h1-sm h3-md arabic-kufi pointer">
                        <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                      </h2>
                      <!-- author & date -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold arabic-kufi" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22 arabic-kufi">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                      <p class="card-text arabic-kufi pointer">{{ publish.desc_ar.substring(0,100)+".." }}</p>
                    </div>
                  </article>
                </div>
                <!--end post big-->
                <!--post small start-->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(21,24)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                          <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" alt="Image description">
                            <!-- <video width="420" onmouseover="this.play()" onmouseout="this.pause()" muted playsinline>
                              <source :src="publish.trailer" type="video/mp4">
                            </video> -->
                            <!-- play button -->
                            <div class="post-type-icon hover-show">
                              <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md arabic-kufi pointer">
                            <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                          </h3>
                          <div class="card-text small text-muted arabic-kufi pointer">
                            <time datetime="2019-10-16">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--End post list-->
                </div>
                <!--end post small-->
                <div class="gap-0"></div>
              </aside>

            </div>
            <!--End sidebar sticky-->
          </aside>
          <!-- end right column -->
        </div>
      </div>
    </main>
    <!--End Content-->
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next';
import { useMeta } from 'vue-meta'
export default {
  name: 'Infographics',
  async setup() {
      useMeta({ title: 'فيديو' })
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const video = ref([]);
      try {
        await HTTP.get(`getVideos.php?LIMIT=30&OFFSET=0`).then((res) => {
          video.value = res.data.getVideos; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (publish) => {
          router.push({ name: "Video", params: { id: publish.id } });
      };
        return {
           video,
           getFeed,
        };
       
   },
  
  // methods: {
  //   getFeed: function (publish) {
  //     this.$cookie.setCookie('Video_Content_id', publish.id, {
  //                expire: 60 * 1,
  //            })
  //       //  this.$cookie.setCookie('Video_Content_id', publish.id)
  //        this.$router.push("/Video");
  //   },
  // },
}
</script>

<style scoped>
.arabic-kufi {
  font-family: 'Zain_Regular', serif;
}
.pointer {
  cursor: pointer;
}
</style>